import React, { useState, useEffect, useContext, useRef } from "react";
import "./HymnBody.css";
import { ChordsContext } from "./ChordsContext";
import { db, storage } from "../firebase/firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { Document, Page, pdfjs } from "react-pdf";
import { useSwipeable } from "react-swipeable";
import Loader from "../components/Loader";
import { deleteObject } from "firebase/storage";
import { updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function HymnBody({ hymnId, pdfUrl }) {
  const {
    zoom,
    setZoom,
    editMode,
    saveMode,
    setFetchedChords,
    setTransposedChords,
    notesMode,
    setNotesMode,
    notesPdfUrl,
    htmlContent,
    setHtmlContent,
    setInitialHtmlContent,
    allChords,
    allChordsObj,
  } = useContext(ChordsContext);
  const contentRef = useRef();
  const containerRef = useRef();
  const [numPages, setNumPages] = useState(null);
  const [initialDistance, setInitialDistance] = useState(null);
  const [initialZoom, setInitialZoom] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfZoom, setPdfZoom] = useState(1);
  const [loading, setLoading] = useState(true);
  const [scrollY, setScrollY] = useState(0);

  let documentClass =
    notesMode && notesPdfUrl ? "document-show" : "document-hide";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    fetchHtmlContent(pdfUrl);
    return () => {
      setZoom(1);
      setPdfZoom(1);
    };
    // eslint-disable-next-line
  }, [pdfUrl]);

  useEffect(() => {
    // Set notesMode to its default value when the component mounts
    setNotesMode(false);
    return () => {
      // Set notesMode to its default value when the component unmounts
      setNotesMode(false);
    };
  }, [setNotesMode]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const isTablet = window.matchMedia(
      "(min-width: 769px) and (max-width: 1024px)"
    ).matches;
    const isComputer = window.matchMedia("(min-width: 1024px)").matches;

    if (isMobile && containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const contentWidth = contentRef.current.scrollWidth;
      const initialZoomLevel = containerWidth / contentWidth;
      setZoom(initialZoomLevel);
    }
    if (isTablet || isComputer) {
      setPdfZoom(1.5);
    } else {
      setPdfZoom(0.8);
    }
    // eslint-disable-next-line
  }, [htmlContent, notesPdfUrl, notesMode]);

  useEffect(() => {}, [notesPdfUrl]);

  useEffect(() => {
    if (saveMode && contentRef.current) {
      uploadChangedFile();
    }
    // eslint-disable-next-line
  }, [saveMode, htmlContent]);

  const fetchHtmlContent = async (url) => {
    setLoading(true);
    if (pdfUrl !== "") {
      const response = await fetch(url);
      const html = await response.text();
      setHtmlContent(html);
      setInitialHtmlContent(html);
      extractChordsFromHtml(pdfUrl);
    }
    setLoading(false);
  };

  const uploadChangedFile = async () => {
    // Fetch existing document for the hymn
    const existingHymnSnapshot = await getDocs(
      query(collection(db, "hymns"), where("title", "==", hymnId))
    );

    if (existingHymnSnapshot.empty) {
      console.log("No existing document found with title: ", hymnId);
      return;
    }

    const hymnDoc = existingHymnSnapshot.docs[0];
    const hymnData = hymnDoc.data();
    const oldFileId = hymnData.fileId; // Store the old file ID

    // Generate a new unique ID for the updated file
    const newFileId = uuidv4();

    // Create a reference to the new file in Firebase Storage
    const newStorageRef = ref(storage, `hymns/${newFileId}`);
    const newStorageChordsRef = ref(storage, `chords/${newFileId}`);

    // Convert htmlContent to Blob
    const newFileBlob = new Blob([htmlContent], { type: "text/html" });

    // Upload the new file data
    await uploadBytes(newStorageRef, newFileBlob);
    await uploadBytes(newStorageChordsRef, newFileBlob);

    // Delete the old file from Firebase Storage
    const oldStorageRef = ref(storage, `hymns/${oldFileId}`);
    const oldStorageChordsRef = ref(storage, `chords/${oldFileId}`);
    await deleteObject(oldStorageRef).catch((error) => {
      console.error("Error deleting old hymn file:", error);
    });
    await deleteObject(oldStorageChordsRef).catch((error) => {
      console.error("Error deleting old chords file:", error);
    });

    // Update Firestore document to point to the new file ID
    await updateDoc(hymnDoc.ref, {
      fileId: newFileId,
    });

    // Reload the page to reflect the changes
    window.location.reload();
  };

  async function extractChordsFromHtml(url) {
    const response = await fetch(url);
    const html = await response.text();

    let parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    let allText = doc.body.textContent;

    // eslint-disable-next-line
    let words = allText.split(/[\s\/-]+/);

    let foundChords = [];

    for (let word of words) {
      word = word.replace(/^\/|\/$/g, "").trim();
      if (allChords.includes(word) && !foundChords.includes(word)) {
        foundChords.push(word);
      }
    }
    setFetchedChords(foundChords);

    const matchingChords = allChordsObj
      .filter(({ value }) => foundChords.includes(value))
      .map((chord) => {
        let originalLabel = foundChords[foundChords.indexOf(chord.value)];
        return { ...chord, label: originalLabel };
      });

    setTransposedChords(matchingChords);
  }

  const handleBlur = (e) => {
    if (editMode) {
      setHtmlContent(e.target.innerHTML);
    }
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      const distance = getDistance(e.touches[0], e.touches[1]);
      setInitialDistance(distance);
      setInitialZoom(zoom);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2 && initialDistance !== null) {
      const distance = getDistance(e.touches[0], e.touches[1]);
      const scaleChange = distance / initialDistance;
      setZoom(initialZoom * scaleChange);
    }
  };

  const getDistance = (touch1, touch2) => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleNextPage = () => {
    setScrollY(window.scrollY);
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    setScrollY(window.scrollY);
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPage(),
    onSwipedRight: () => handlePreviousPage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const onDocumentKeyDown = (event) => {
      switch (event.key) {
        case "ArrowRight":
          handleNextPage();
          break;
        case "ArrowLeft":
          handlePreviousPage();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", onDocumentKeyDown);
    return () => {
      window.removeEventListener("keydown", onDocumentKeyDown);
    };
    // eslint-disable-next-line
  }, [pageNumber, numPages]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 50);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [pageNumber]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="hymn-document" ref={containerRef}>
          {!notesMode && (
            <div
              className="center-container"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            >
              <div
                ref={contentRef}
                className="editable-content"
                contentEditable={editMode}
                suppressContentEditableWarning
                onBlur={(e) => handleBlur(e)}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                style={{
                  transform: `scale(${zoom})`,
                  margin: editMode ? "0" : "auto",
                  marginTop: zoom < 1 ? "-6rem" : "auto",
                  marginLeft: zoom < 1 ? "1rem" : "auto",
                  paddingBottom: "4rem",
                }}
              />
            </div>
          )}
          <div
            className={documentClass}
            {...handlers}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <Document file={notesPdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <div
                key={`page_container_${pageNumber}`}
                className="page-container"
              >
                <Page
                  key={`page_${pageNumber}`}
                  pageNumber={pageNumber}
                  scale={pdfZoom}
                  renderTextLayer={false}
                />
              </div>
            </Document>
            <div className="controls">
              <button onClick={handlePreviousPage} disabled={pageNumber <= 1}>
                Προηγούμενη
              </button>
              <span>
                Σελίδα {pageNumber} από {numPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={pageNumber >= numPages}
              >
                Επόμενη
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HymnBody;
