import React, { useState, useEffect, useContext } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useSearchParams } from "react-router-dom";
import { storage } from "../firebase/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import HymnHeader from "../components/HymnHeader";
import HymnBody from "../components/HymnBody";
import Loader from "../components/Loader";
import "./Hymn.css";
import { majorScales, minorScales } from "../assets/scales";
import { ChordsContext } from ".././components/ChordsContext";
import { useNavigate } from "react-router-dom";
import { setItem, getItem } from ".././indexedDB";

export const Hymn = () => {
  const navigate = useNavigate();
  const {
    setHymnId,
    setHymnNumber,
    setNotesPdfUrl,
    setNotesMode,
    setCurrentScale,
    setResetTranspose,
  } = useContext(ChordsContext);
  const [searchParams] = useSearchParams();
  const hymnId = searchParams.get("title");
  const [pdfURL, setPdfURL] = useState("");
  const [isHymnPublic, setIsHymnPublic] = useState(false);
  const [hymnScale, setHymnScale] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setHymnId(hymnId);
    setResetTranspose((prev) => prev + 1);
    setTimeout(() => setResetTranspose(0), 0);
    setNotesPdfUrl("");
    // eslint-disable-next-line
  }, [hymnId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        let q = query(collection(db, "hymns"), where("title", "==", hymnId));
        const existingHymnSnapshot = await getDocs(q, { source: "cache" });

        const hymnDoc = existingHymnSnapshot.docs[0];
        let hymnData = null;

        if (!hymnDoc) {
          const serverQuerySnapshot = await getDocs(q, { source: "server" });
          const serverHymnDoc = serverQuerySnapshot.docs[0];
          hymnData = serverHymnDoc.data();
          if (!serverHymnDoc) {
            console.error(`No document found with title: ${hymnId}`);
            setLoading(false);
            return;
          }
        } else hymnData = hymnDoc.data();

        if (hymnData) {
          const hymnScale = hymnData.scale;
          let selectedScale = majorScales
            .concat(minorScales)
            .find((option) => option.value === hymnScale);
          if (selectedScale) {
            setCurrentScale(selectedScale.label);
          }
          setHymnNumber(hymnData.number);
          setIsHymnPublic(hymnData.visible);
          setHymnScale(hymnData.scale);

          if (hymnData.fileId !== "") fetchPdf(hymnData.fileId);
          if (hymnData.notesFileId !== "") fetchNotesPdf(hymnData.notesFileId);
          if (hymnData.fileId === "" && hymnData.notesFileId)
            setNotesMode("notes");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [hymnId]);

  const fetchPdf = async (fileId) => {
    try {
      const storageRef = ref(storage, "hymns/" + fileId);
      getDownloadURL(storageRef)
        .then((url) => {
          setPdfURL(url);
        })
        .catch((error) => {
          console.error("Error getting download URL:", error);
          navigate("/NotFound");
        });
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const fetchNotesPdf = async (notesFileId) => {
    try {
      const storageRef = ref(storage, "hymns/");
      // const storageRef = ref(storage, "hymns/" + notesFileId);
      let cachedHymnDocument = await getItem(notesFileId);

      if (cachedHymnDocument) {
        setNotesPdfUrl(cachedHymnDocument);
      } else {
        // Fetch from Firebase Storage
        const hymnRef = ref(storageRef, notesFileId);
        const url = await getDownloadURL(hymnRef);
        // Store the image in IndexedDB
        await setItem(notesFileId, url);
        setNotesPdfUrl(url);
      }

      // getDownloadURL(storageRef)
      //   .then((url) => {
      //     setNotesPdfUrl(url);
      //   })
      //   .catch((error) => {
      //     console.error("Error getting download notes URL:", error);
      //     navigate("/NotFound");
      //   });
    } catch (error) {
      console.error("Error fetching PDF:", error);
      setNotesPdfUrl(null);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HymnHeader
            isHymnPublic={isHymnPublic}
            hymnScale={hymnScale}
            pdfUrl={pdfURL}
          />
          <HymnBody
            hymnId={hymnId}
            pdfUrl={pdfURL}
            className={`${loading ? "loading" : ""}`}
          />
        </>
      )}
    </div>
  );
};
