import { React, useState, useRef, useEffect } from "react";
import { db, storage } from "../firebase/firebase";
import {
  collection,
  setDoc,
  doc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { ref, uploadBytes } from "firebase/storage";
import { ToastService } from "../components/Toast";
import Form from "react-bootstrap/Form";
import "./FileUpload.css";
import { majorScales, minorScales } from "../assets/scales";
import validate from "validate.js";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../contexts/authContext/Index";
import { useNavigate } from "react-router-dom";

export const FileUpload = () => {
  const [showToastService, setShowToastService] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedNotesFile, setSelectedNotesFile] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedScale, setSelectedScale] = useState("0");
  const [selectedNumber, setSelectedNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const fileInputRef = useRef(null);
  const fileNotesInputRef = useRef(null);
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();

  useEffect(() => {
    if (!userLoggedIn) navigate("/Login");
  }, [userLoggedIn, navigate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/html") {
      setSelectedFile(e.target.files[0]);
    } else {
      showToastMessage("Παρακαλώ επιλέξτε αρχείο σε μορφή html.");
    }
  };

  const handleNotesFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedNotesFile(e.target.files[0]);
    } else {
      showToastMessage("Παρακαλώ επιλέξτε αρχείο σε μορφή pdf.");
    }
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;

    if (!title) {
      setSelectedTitle(title);
      return;
    }
    const constraints = { title: { format: /[\u0370-\u03FF \-',.0-9]+/ } };
    const validation = validate({ title }, constraints);

    if (!validation) {
      setSelectedTitle(title);
    } else {
      showToastMessage(
        "Ο τίτλος πρέπει να περιλαμβάνει μόνο ελληνικούς χαρακτήρες"
      );
    }
  };

  const handleNumberChange = (e) => {
    setSelectedNumber(e.target.value);
  };

  const handleScaleChange = (e) => {
    setSelectedScale(e.target.value);
    var selected = majorScales
      .concat(minorScales)
      .find((option) => option.value === e.target.value);
    if (selected) setSelectedImage(selected.image);
  };

  const handleUpload = async () => {
    // if (!selectedFile) {
    //   showToastMessage("Παρακαλώ επιλέξτε αρχείο");
    //   return;
    // } else
    if (!selectedTitle) {
      showToastMessage("Παρακαλώ επιλέξτε όνομα ύμνου");
      return;
    } else if (selectedScale === "0") {
      showToastMessage("Παρακαλώ επιλέξτε τονικότητα");
      return;
    } else {
      // Check if title already exists
      const hymnQuerySnapshot = await getDocs(
        query(collection(db, "hymns"), where("title", "==", selectedTitle))
      );

      if (!hymnQuerySnapshot.empty) {
        showToastMessage("Ο τίτλος υπάρχει ήδη");
        return;
      }
      let fileId = "";
      let notesFileId = "";
      if (selectedFile) {
        fileId = uuidv4();
        const storageRef = ref(storage, `hymns/${fileId}`);
        // Upload the file data
        await uploadBytes(storageRef, selectedFile);

        const storagechordsRef = ref(storage, `chords/${fileId}`);
        // Upload the file data
        await uploadBytes(storagechordsRef, selectedFile);
      }

      if (selectedNotesFile) {
        const storageNotesRef = ref(storage, `hymns/${notesFileId}`);
        // Upload the file data
        notesFileId = uuidv4();
        await uploadBytes(storageNotesRef, selectedNotesFile);

        const storagenotesNotesRef = ref(storage, `notes/${notesFileId}`);
        // Upload the file data
        notesFileId = uuidv4();
        await uploadBytes(storagenotesNotesRef, selectedNotesFile);
      }

      //add it to hymns collection
      const myCollection = collection(db, "hymns");
      const hymnData = {
        title: selectedTitle,
        scale: selectedScale,
        number: selectedNumber,
        fileId: fileId,
        notesFileId: notesFileId,
        visible: false,
      };

      // Define the document reference
      const myDocRef = doc(myCollection, selectedTitle);
      await setDoc(myDocRef, hymnData);

      clearFileInput();
      setSelectedTitle("");
      setSelectedScale("");
      setSelectedNumber("");
      setSelectedNotesFile();
      showToastMessage("Επιτυχία Ανεβάσματος");
    }
  };

  const clearFileInput = () => {
    fileInputRef.current.value = "";
  };

  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToastService(!showToastService);
    setTimeout(() => {
      setShowToastService(false);
    }, 1500);
  };

  return (
    <div className="file-upload-container">
      <h4 className="text-center font-bold">Προσθήκη Νέου Ύμνου</h4>
      <div className="text-center text-xl pt-5">
        <div className=" pt-2 mx-auto">
          <Form.Label>Τίτλος</Form.Label>
          <Form.Control
            type="text"
            value={selectedTitle}
            onChange={handleTitleChange}
            placeholder="Όνομα τίτλου"
          />
        </div>
        <div className="mx-auto pt-4">
          <Form.Label>Αρχείο (στίχοι με συγχορδίες)</Form.Label>
          <Form.Control
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </div>
        <div className="mx-auto pt-4">
          <Form.Label>Αρχείο με νότες (προαιρετικά)</Form.Label>
          <Form.Control
            type="file"
            ref={fileNotesInputRef}
            onChange={handleNotesFileChange}
          />
        </div>
        <div className="mx-auto pt-4">
          <Form.Label>Αριθμός Ύμνου (προαιρετικά)</Form.Label>
          <Form.Control
            type="number"
            min="1"
            value={selectedNumber}
            onChange={handleNumberChange}
            placeholder="Αριθμός"
          />
        </div>
        <div className="w-60 pt-4 pb-4 mx-auto">
          <Form.Label>Τονικότητα</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={selectedScale}
            onChange={handleScaleChange}
          >
            <option key="0" value="0" label="Τονικότητα"></option>
            {majorScales.concat(minorScales).map((option) => (
              <option
                key={option.value}
                value={option.value}
                style={{ backgroundImage: `url(${option.image})` }}
              >
                {option.label}
              </option>
            ))}
          </Form.Select>
          <img className="pt-4 mx-auto" src={selectedImage} alt="" />
        </div>
        <Button variant="primary" onClick={handleUpload}>
          Ανέβασμα
        </Button>
        {showToastService && <ToastService message={toastMessage} />} {}
      </div>
    </div>
  );
};
